import React from 'react';
import { useEnvironment, useTranslation, useFedopsLogger } from 'yoshi-flow-editor-runtime';
import { PublicPlan } from '@wix/ambassador-pricing-plans-read-api/http';
import { useSettings, useStyles } from 'yoshi-flow-editor-runtime/tpa-settings/react';
import loadable from '@loadable/component';

import { st, classes } from './List.st.css';
import { PlansList } from './PlanList';
import { TabState, PopupEnum, MessageCode } from '../../../../types/common';
import { TextAlignment } from '../../settingsParams';
import { messageToText, isMessageInformational } from '../../controller/errors';
import { PackagePickerInteractions } from '../../../../types/PackagePickerFedops';
import { Toast } from '../Toast';
import { useSettingsParams, useStylesParams } from '../../../../hooks';

const LazyPopup = loadable(() => import('./Popup'));

export interface ListProps {
  plans: PublicPlan[];
  tabState: TabState;
  popup: PopupEnum | null;
  message?: MessageCode;
  dataHook?: string;
  selectPlan(plan: PublicPlan): void;
  hidePopup(): void;
  hideToast(): void;
  closeUpgradeModal(): void;
}

const List: React.FC<ListProps> = ({ plans, tabState, selectPlan, popup, hidePopup, message, dataHook, hideToast }) => {
  const { t } = useTranslation();
  const { isMobile } = useEnvironment();
  const fedops = useFedopsLogger();
  const settingsParams = useSettingsParams();
  const stylesParams = useStylesParams();
  const settings = useSettings();
  const styles = useStyles();
  const showPageTitle = styles.get(stylesParams.showPageTitle);
  const showDisclaimer = styles.get(stylesParams.showDisclaimer);
  const textAlignment = textAlignmentName(styles.get(stylesParams.textAlignment));

  React.useEffect(() => {
    fedops.interactionEnded(PackagePickerInteractions.ListPageLoaded);
  }, []);

  return (
    <div
      className={st(classes.root, { mobile: !!isMobile, textAlignment })}
      data-hook={dataHook ?? 'PackagePicker-wrapper'}
    >
      {plans.length ? (
        <>
          <div className={classes.header}>
            {showPageTitle && (
              <h1 data-hook="app-title">{settings.get(settingsParams.pageHeaderText) || t('pp.defaults.pageTitle')}</h1>
            )}
          </div>
          {message ? (
            <Toast onClose={hideToast} informational={isMessageInformational(message)}>
              {t(messageToText(message))}
            </Toast>
          ) : null}
          <PlansList selectPlan={selectPlan} plans={plans} showDemoHighlight={tabState === TabState.HIGHLIGHTED} />
          {showDisclaimer && (
            <div className={classes.disclaimer}>
              <p>{settings.get(settingsParams.disclaimerText)}</p>
            </div>
          )}
        </>
      ) : (
        <h1 data-hook="empty-state-message" className={st(classes.empty, { mobile: !!isMobile })}>
          {t('pp.no-plans')}
        </h1>
      )}
      {popup ? <LazyPopup popup={popup} hidePopup={hidePopup} /> : null}
    </div>
  );
};

export default List;

enum TextAligmentName {
  LEFT = 'left',
  CENTER = 'center',
  RIGHT = 'right',
}

const textAlignmentMap = {
  [TextAlignment.LEFT]: TextAligmentName.LEFT,
  [TextAlignment.CENTER]: TextAligmentName.CENTER,
  [TextAlignment.RIGHT]: TextAligmentName.RIGHT,
};

function textAlignmentName(value: TextAlignment): TextAligmentName {
  return textAlignmentMap[value];
}
