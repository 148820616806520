import React from 'react';
import { PublicPlan } from '@wix/ambassador-pricing-plans-read-api/http';
import { Button } from 'wix-ui-tpa/Button';
import { useSettings, useStyles } from 'yoshi-flow-editor-runtime/tpa-settings/react';
import { useTranslation } from 'yoshi-flow-editor-runtime';

import { ButtonStyle } from '../../../settingsParams';
import { st, classes } from './Cta.st.css';
import stylesParams from '../../../stylesParams';
import { useSettingsParams } from '../../../../../hooks';

interface Props {
  plan: PublicPlan;
  highlighted: boolean;
  onClick: () => void;
}

export const Cta: React.FC<Props> = ({ highlighted, plan, onClick }) => {
  const settingsParams = useSettingsParams();
  const settings = useSettings();
  const { t } = useTranslation();
  const styles = useStyles();
  const hasFreeTrial = !!plan.paymentOptions?.freeTrialDays;

  const renderText = () => {
    if (hasFreeTrial) {
      return settings.get(settingsParams.freeTrialButtonText) || t('pp.defaults.freeTrialButton');
    }

    return settings.get(settingsParams.buttonText) || t('pp.defaults.button');
  };

  const getStyle = () => {
    const buttonStyle = styles.get(stylesParams.buttonStyle);

    switch (buttonStyle) {
      case ButtonStyle.EMPTY_CORNERED:
        return classes.buttonEmptyCornered;
      case ButtonStyle.EMPTY_ROUNDED:
        return classes.buttonEmptyRounded;
      case ButtonStyle.FULL_ROUNDED:
        return classes.buttonFullRounded;
      default:
      case ButtonStyle.FULL_CORNERED:
        return classes.buttonFullCornered;
    }
  };

  return (
    <Button data-hook="plan-cta" onClick={onClick} className={st(getStyle(), { highlighted })}>
      {renderText()}
    </Button>
  );
};
