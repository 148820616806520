import React, { useState } from 'react';
import Collapsible from 'react-collapsible';
import { useStyles } from 'yoshi-flow-editor-runtime/tpa-settings/react';
import { useEnvironment } from 'yoshi-flow-editor-runtime';

import { st, classes } from './Footer.st.css';
import { ArrowDown } from './ArrowDown';
import { useStylesParams } from '../../../../../hooks';

interface Props {
  highlighted: boolean;
  anyBenefits?: boolean;
  benefits?: String[];
}

export const Footer: React.FC<Props> = ({ benefits, anyBenefits, highlighted }) => {
  const { isMobile } = useEnvironment();
  const [expanded, setExpanded] = useState<boolean>(!isMobile);
  const stylesParams = useStylesParams();
  const styles = useStyles();
  const showPlanBenefits = styles.get(stylesParams.showBenefits);

  if (!showPlanBenefits || !anyBenefits || !benefits) {
    return null;
  }

  const renderBenefits = () => {
    return (
      <ul className={st(classes.benefits, { highlighted })}>
        {benefits.map((benefit, i) => (
          <li key={i}>{benefit}</li>
        ))}
      </ul>
    );
  };

  if (!isMobile) {
    return renderBenefits();
  }

  return (
    <div onClick={() => setExpanded(!expanded)} style={{ flex: 1 }}>
      <Collapsible trigger={''} open={expanded} easing="ease-out">
        {renderBenefits()}
      </Collapsible>
      {<ArrowDown className={st(classes.arrow, { expanded, highlighted })} />}
    </div>
  );
};
