import React, { FC } from 'react';
import { useSettings } from 'yoshi-flow-editor-runtime/tpa-settings/react';
import { WidgetProps } from 'yoshi-flow-editor-runtime';

import List, { ListProps } from '../../PackagePicker/Widget/List';
import settingsParams from '../settingsParams';
import stylesParams from '../stylesParams';
import { PlanListSettingsAdapter } from '../PlanListSettingsAdapter';
import { SettingsAdapterProvider, StylesParamsProvider } from '../../../hooks';

const Widget: FC<WidgetProps<ListProps>> = (props) => {
  const settings = useSettings();
  const settingsAdapter = React.useMemo(() => new PlanListSettingsAdapter(settings, settingsParams), [settings]);
  return (
    <StylesParamsProvider stylesParams={stylesParams}>
      <SettingsAdapterProvider adapter={settingsAdapter}>
        <List {...props} dataHook="PlanList-wrapper" />
      </SettingsAdapterProvider>
    </StylesParamsProvider>
  );
};

export default Widget;
